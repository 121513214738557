:root {
  --referenceListTableBreakpoint: desktop;
}
@media (max-width: 767px) {
  :root {
    --referenceListTableBreakpoint: mobile;
  }
}
:root {
  --deskWidth: 1156px;
  --deskSpace: 40px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 12px;
  }
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 40px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 50px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #FAFAFA;
  line-height: 1.33333333;
  cursor: pointer;
  color: #333333;
  padding: 16px 112px 16px 32px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  background-size: 16px 26px;
  background-position: right 32px center;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-right-lightgray.svg);
  transition: all 0.3s;
}
.button:hover,
.button:focus {
  background-color: #E0E0E0;
  background-image: url(/images/chevron-right-darkgray.svg);
  background-position: right 22px center;
}
@media (max-width: 1023px) {
  .button {
    padding-left: 20px;
    padding-right: 56px;
    background-position: right 20px center;
  }
  .button:hover,
  .button:focus {
    background-position: right 14px center;
  }
}
@media (max-width: 767px) {
  .button {
    width: 100%;
  }
}
.area--one .unit.wide {
  float: right;
  width: 58.13953488%;
  margin-left: 41.86046512%;
}
.area--one .unit.slim {
  width: 37.79069767%;
  margin-bottom: -100%;
}
.area--one .unit.slim + .wide {
  margin-top: calc((var(--spaceTotal) * 2) - ((var(--spacePart) * 2) + 6px));
}
.area--one .unit.slim + .wide.seam {
  margin-top: calc((var(--spaceTotal) * 2) - (var(--spacePart) + 6px));
}
.area--one .flat {
  width: 100% !important;
  margin-left: 0 !important;
  min-height: 280px;
}
.area--one .flat .body {
  padding-right: 62.20930233%;
}
.area--one .flat .pict.tall:first-child {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(100% - (var(--spacePart) * 2));
  width: 58.13953488%;
}
.area--one .flat .pict.tall:first-child * {
  height: 100%;
}
.area--one .flat .pict.tall:first-child img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
/*# sourceMappingURL=./screen-medium.css.map */